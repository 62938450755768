import React from 'react'
import { FooterContainer } from '../containers/Footer';
import website from '../../config/website'

const Footer = () => (
  <FooterContainer>
    <div className="container">
      <div>
        <p>
          © 2021 <a href="https://www.davidjarre.com">davidjarre.com</a>
        </p>
      </div>
      <div>
        <ul className="footer-social">
          <li>
            <a href={website.instagram} target="_blank" rel="noreferrer">
              <i className="socicon-instagram"></i>
            </a>
          </li>
          {/* <li>
            <a href={website.facebook} target="_blank" rel="noreferrer">
              <i className="socicon-facebook"></i>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  </FooterContainer>
)

export default Footer;