import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'gatsby'
import { HeaderWrapper } from '../containers/Header'
import website from '../../config/website'
import i18n from '../../config/i18n'
import ModalContainer from '../components/ModalContainer'
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerIcon";
import Menu from "./Menu";

class Header extends React.Component {

  state = {
    open: false
  }

  componentDidMount() {
    this.manageLayout();
  }

  componentDidUpdate() {
    this.manageLayout()
  }

  manageLayout = () => {
    const parentNode = ReactDOM.findDOMNode(this).parentNode;
    this.wrapper = parentNode.querySelector(`.header-container`);
    this.burgerMenu = parentNode.querySelector(`.burger-menu`);
    if (this.props.slug === '/') {
      if (parentNode.querySelector('.intro') !== null) {
        window.addEventListener('load', this.handleLoad);
        setTimeout(() => {
          this.bannerHeight = parentNode.querySelector('.intro').clientHeight;
          window.addEventListener('scroll', this.handleScroll, { passive: true });
          if (window.scrollY > 50) {
            this.wrapper.className = `header-container white`;
            this.burgerMenu.className = `burger-menu white`;
          } else {
            this.wrapper.className = `header-container`;
            this.burgerMenu.className = `burger-menu white`;
          }
        }, 500);
      }
    } else {
      this.wrapper.className = `header-container white`;
      this.burgerMenu.className = `burger-menu white`;
    }
  }

  handleLoad = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > this.bannerHeight) {
      this.wrapper.className = `header-container white`;
      this.burgerMenu.className = `burger-menu white`;
    }
  }

  handleScroll = (e) => {
    if (e.srcElement.scrollingElement.scrollTop > this.bannerHeight) {
      this.wrapper.className = `header-container white`;
      this.burgerMenu.className = `burger-menu white`;
    } else {
      this.wrapper.className = `header-container`;
      this.burgerMenu.className = `burger-menu white`;
    }
  }

  handleClickMobileMenu = () => {
    this.setState({
      open: !this.state.open
    });
  }

  expandFaqRow = () => {
    this.props.setRow(1);
    this.props.expand();
  }

  render() {

    const { slug, locale, alternates } = this.props;
    
    return (
      <HeaderWrapper className="header">
        <div className="header-container white">
          <div>
            <div className="menu">
              {
                <React.Fragment>
                  <ModalContainer
                    triggerText={i18n[locale].buttons[4]}
                    locale={locale}
                    button={true}
                  />
                  <Link activeClassName="active" to={locale === 'fr' ? '/evenements/' : '/en/events/'}>
                    {i18n[locale].labels[5]}
                  </Link>
                  <Link activeClassName="active" to={locale === 'fr' ? '/a-propos/' : '/en/about/'}>
                    {i18n[locale].labels[4]}
                  </Link>
                  <Link className="mfh" to={locale === 'fr' ? '/' : '/en'}>
                    {i18n[locale].labels[6]}
                  </Link>
                </React.Fragment>
              }
            </div>
            <div className="menu-mobile">
              <Popup
                modal
                overlayStyle={{ background: "rgba(255,255,255,0.98" }}
                contentStyle={{
                  background: "rgba(255,255,255,0)",
                  width: "80%",
                  border: "none"
                }}
                closeOnDocumentClick={true}
                trigger={open => <BurgerIcon open={open} />}
              >
                {close => (
                  <Menu 
                    close={close}
                    locale={locale}
                    alternates={alternates}
                    slug={slug}
                    onSubmit={this.onSubmit}
                    location={this.props.location}
                    expandFaqRow={this.expandFaqRow}
                  />
                )}
              </Popup>
            </div>
          </div>
          <div className="title">
            {
              locale === 'fr' ? (
                <Link to="/">David Jarre</Link>
              ) : (
                <Link to="/en">David Jarre</Link>
              )
            }
          </div>
          <div className="social">
            {
              alternates ? (
                <ul className="locale">
                  <li><Link to={alternates.fr} className={`${locale === 'fr' ? 'active' : 'default'}`}>FR</Link></li>
                  <li><Link to={alternates.en} className={`${locale === 'en' ? 'active' : 'default'}`}>EN</Link></li>
                </ul>
              ) : (
                <ul className="locale">
                  <li><Link to={`/${slug === '/' ? '' : `${slug}/`}`} className={`${locale === 'fr' ? 'active' : 'default'}`}>FR</Link></li>
                  <li><Link to={`/en${slug === '/' ? '/' : `/${slug}/`}`} className={`${locale === 'en' ? 'active' : 'default'}`}>EN</Link></li>
                </ul>
              )
            }
            <ul className="header-social">
              <li>
                <a href={website.instagram} target="_blank" rel="noreferrer">
                  <i className="socicon-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </HeaderWrapper>
    )
  }
}

export default Header;